.navbar {
    width: 100%;
    height: 100px;
}

.links {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.navbar a {
    color: white;
    text-decoration: none;
    margin: 20px;
    font-size: 25px;
}

.navbar a:hover {
    color: #f77062;
    transition: all 0.3s ease;
}

.toggleButton {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.toggleButton svg {
    font-size: 50px;
}
.toggleButton button {
    margin-right: 20px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
}

#open {
    height: 100vh;
}

#open .links {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
}

#open a {
    width: 100%;
    text-align: center;
}

/*@media only screen and (max-width: 900px) {
    .navbar a {
        width: 70px;
    }
}
*/
@media only screen and (max-width: 899px) {
    .toggleButton {
        display: flex;
    }
    #closed a {
        display: none;
    }
}

@media only screen and (min-width: 900px) {
    .toggleButton {
        display: none;
    }

    .hiddenLinks {
        display: none;
    }

    #open {
        height: 100px;
    }

    #open .links {
        flex-direction: row;
    }
}