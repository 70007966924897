span {
    color: #fff;

}

.experience {
    text-align: left;
}

@media only screen and (max-width:1169px) {
    span {
        color: #000000;
    }
}