body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.home {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
    color: #f0f0f0;
}

.picture {
    margin-top: 50px;
    flex: 1 1;
    height: 100%;
}

.picture img {
    margin: auto;
    border-radius: 50%;
    vertical-align: middle;
}

.picture-right-pad {
    padding-right: 10%;
}

.about {
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #f0f0f0;
}

.about h2 {
    font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
    font-size: 60px;
    color: #ff0844;

    background-color: #ff8177;
    background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;

    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;

}

.sbs-home-container {
    margin: auto;
    display: flex;
    align-items: center !important;
    width: 75%;
}

.prompt {
    margin: auto;
    width: 50%;
    display: inline;
    align-items: center;
    padding-right: 10%;
}

.prompt p {
    font-size: 24px;
}

.socialMediaHome svg {
    font-size: 60px;
    margin: 5px;
    color: white;
}

.socialMediaHome a {
    text-decoration: none;
}

.socialMediaHome svg:hover {
    color: #f77062;
    transition: all 0.3s ease;
    text-decoration: none;
}

.biography {
    margin: auto;
    width: 65%;
    display: inline;
    align-items: center;
    font-size: 24px;
}

.biography h1 {
    background-image: linear-gradient(to top, #b721ff 0%, #21d4fd 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;

    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.biography p {
    font-size: 20px;
}

/*.skills {
    font-size: 35px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}*/

@media only screen and (max-width: 600px) {

    .about h2 {
        font-size: 40px;
    }

    .about .prompt {
        margin-top: 10px;
        font-size: 20px;
        width: 80%;
    }

    .skills {
        text-align: center;
    }
    .list {
        padding: 0;
    }

    .skills h2 {
        font-size: 30px;
    }
}

@media only screen and (max-width: 900px) {
    .sbs-home-container {
        flex-direction: column;
    }

    .picture-right-pad {
        padding-right: 0;
    }

    .prompt {
        padding-right: 0;
    }
}
.projects {

    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

h1 {
    color: #f0f0f0;
}

.projects .projectTitle {
    text-align: center;
    color: #f0f0f0;
    font-size: 60px;
    font-weight: bolder;
}

.projectList {
    width: 70vw;
    height: auto;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    place-items: center;
}

/* MENU ITEM STYLING */

.projectItem {
    border-radius: 15px;
    width: 300px;
    height: 300px;
    margin: 40px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
}
.projectItem:hover {
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.5);
    transition: 0.3s ease-in;
    cursor: pointer;
}

.projectItem .bgImage {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    width: 100%;
    height: 200px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.projectItem h1 {
    font-size: 25px;
}

@media only screen and (max-width: 1300px) {
    .projectList {
        grid-template-columns: 1fr 1fr;
    }
}

@media only screen and (max-width: 800px) {
    .projectList {
        grid-template-columns: 1fr;
    }
}

@media only screen and (max-width: 800px) {
    .projectList {
        width: 100%;
    }
    .projectItem {
        width: 300px;
        height: 300px;
    }
}
span {
    color: #fff;

}

.experience {
    text-align: left;
}

@media only screen and (max-width:1169px) {
    span {
        color: #000000;
    }
}
.navbar {
    width: 100%;
    height: 100px;
}

.links {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.navbar a {
    color: white;
    text-decoration: none;
    margin: 20px;
    font-size: 25px;
}

.navbar a:hover {
    color: #f77062;
    transition: all 0.3s ease;
}

.toggleButton {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.toggleButton svg {
    font-size: 50px;
}
.toggleButton button {
    margin-right: 20px;
    background-color: transparent;
    border: none;
    color: white;
    cursor: pointer;
}

#open {
    height: 100vh;
}

#open .links {
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 100px;
}

#open a {
    width: 100%;
    text-align: center;
}

/*@media only screen and (max-width: 900px) {
    .navbar a {
        width: 70px;
    }
}
*/
@media only screen and (max-width: 899px) {
    .toggleButton {
        display: flex;
    }
    #closed a {
        display: none;
    }
}

@media only screen and (min-width: 900px) {
    .toggleButton {
        display: none;
    }

    .hiddenLinks {
        display: none;
    }

    #open {
        height: 100px;
    }

    #open .links {
        flex-direction: row;
    }
}
.footer {
    width: 100%;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-top: 10px;
    /*margin-top: 200px;*/
}

.socialMedia svg {
    color: white;
    margin: 5px;
    font-size: 40px;
    cursor: pointer;
}

.socialMedia a {
    text-decoration: none;
}

.socialMedia svg:hover {
    color: #f77062;
    transition: all 0.3s ease;
    text-decoration: none;
}

.footer p {
    color: white;
}

@media only screen and (max-width: 600px) {
    .footer svg {
        font-size: 50px;
    }
}
.App {
    background-color: #141414;
    text-align: center;
}

body {
    padding: 0;
    margin: 0;
}

hr {
    background: linear-gradient(
            to right,
            #000 0%,
            #f77062 20%,
            #4837ff 80%,
            #000 100%
    );
    border: none;
    height: 1px;
}

a {
    color: #21d4fd;
    text-decoration: none;
}

a:hover {
    color: #f77062;
    transition: all 0.3s ease;
    text-decoration: none;
}
.project {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.project h1 {
    margin-top: 50px;
    color: #f0f0f0;
    font-size: 35px;
}
.project img {
    margin: auto;
    width: 500px;
    border-radius: 10px;
}

.project p {
    font-size: 24px;
    width: 50%;
    color: #f0f0f0;
}

.contactMe {
    width: 100%;
    align-items: center;
    font-family: "Arial", sans-serif;
    color: #f0f0f0;
    min-height: calc(100vh - 300px);
}

.contactMe h2 {
    font-size: 32px;
    background-color: #ff8177;
    background-image: linear-gradient(to top, #b721ff 0%, #21d4fd 100%);
    background-size: 100%;
    -webkit-background-clip: text;
    -moz-background-clip: text;

    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
}

.contactMe p {
    margin: auto;
    width: 50%;
    font-size: 20px;
}

