.project {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.project h1 {
    margin-top: 50px;
    color: #f0f0f0;
    font-size: 35px;
}
.project img {
    margin: auto;
    width: 500px;
    border-radius: 10px;
}

.project p {
    font-size: 24px;
    width: 50%;
    color: #f0f0f0;
}
